<template>
  <div>
    <div class="div-input">
      <div :class="['input-custom', { error: isValidate }]">
        <label>
          {{ textFloat }}
          <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <div class="d-flex justify-content-center align-items-center">
          <div class="w-100 display-only">
            <input
              :class="['custom-input']"
              :type="type.image"
              :placeholder="placeholder"
              :name="name"
              :required="required"
              :value="fileName"
              :size="size"
              disabled
            />
          </div>
          <label class="mb-0 btn-main bg-gold" :size="size">
            <input
              type="file"
              v-on:change="handleFileChange"
              :required="required"
              :accept="accept"
              :name="name"
              ref="input"
              :disabled="disabled"
              @click="clearValue"
            />
            <font-awesome-icon
              icon="file-upload"
              color="white"
              class="bg-icon mr-2"
              :size="size"
            />Choose File
          </label>
        </div>
      </div>
      <p class="detail-format">{{ text }}</p>
      <div v-if="v && v.$error">
        <span class="text-error" v-if="v.required == false">{{
          required
        }}</span>
      </div>
    </div>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  components: {
    ModalAlertError,
  },
  props: {
    textFloat: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    format: {
      required: true,
      type: String,
    },
    fileName: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: true,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    downloadPath: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    accept: {
      required: true,
      type: String,
    },
    required: {
      type: String,
      default: "Please select cover image.",
    },
  },
  data() {
    return {
      file: "",
      value: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
      },
      modalMessage: "",
    };
  },
  methods: {
    clearValue(e) {
      e.target.value = "";
    },
    handleFileChange(e) {
      this.value = e.target.files[0];
      if (e.target.files.length) {
        if (
          this.value.size > 150000000 &&
          (this.format == "video" || this.format == "all")
        ) {
          alert("This file size is too large");
          return;
        }
      }
      var _validFileExtensions = ["image/jpeg", "image/png", "video/mp4"];
      if (e.target.files.length) {
        if (_validFileExtensions.indexOf(this.value.type) < 0) {
          this.$refs.input.value = "";
          this.modalMessage = "File not support";
          this.$refs.modalAlertError.show();
        } else if (this.value.size > 150000000) {
          this.modalMessage = "This file size is too large";
          this.$refs.modalAlertError.show();
        } else {
          this.$emit("onFileChange", this.value);
        }
      } else {
        this.value = "";
        this.$refs.input.value = "";
      }
    },
  },
};
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.detail-format {
  color: #9b9b9b;
  font-size: 12px;
  font-family: "Kanit-Light";
  margin-top: 3px;
  margin-bottom: 0px;
  /* height: 35px; */
}
.bg-icon.fa-lg {
  margin: 8px 0px;
}
.bg-gold {
  background: var(--secondary-color) !important;
}
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}
.input-custom > label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}
input.custom-input {
  color: var(--font-main-color);
  background-color: white;
  border: 1px solid #d8dbe0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 7px 10px;
  display: block;
  width: 100%;
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: 15px;
  font-family: "Kanit-Light";
}
.display-only {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-main {
  width: 120px;
  text-align: center;
  height: 37px;
  vertical-align: middle;
  cursor: pointer;
  background: #16274a;
  color: white;
  padding: 7px 10px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.text-error {
  color: red;
}
@media (max-width: 767.98px) {
  .input-custom > label {
    font-size: 15px;
  }
  .detail-format {
    font-size: 11px;
  }
}
</style>
